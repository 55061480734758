/* DatePicker.css */

/* Custom styles for the calendar container */
.custom-datepicker {
    background-color: white; /* Set background color to white */
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 14px;
  }
  
  /* Custom styles for the selected date */
  .custom-datepicker__input-container input {
    border: none;
    outline: none;
  }
  
  /* Custom styles for the calendar pop-up */
  .custom-datepicker__calendar-container {
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Custom styles for the month container */
  .react-datepicker__month-container, .react-datepicker__header{
    /* Add your custom styles for the month container here */
    /* For example, you can set a background color and adjust padding */
    background-color: #ffffff;
    padding: 6px;
  }
  
  /* Customize any other styles as needed */
  