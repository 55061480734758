/* TimelineView.css */
.vertical-timeline-element-content p {
  margin: 0 !important;
  line-height: 1.4;
  font-weight: 600
}

.vertical-timeline-element {
  position: relative;
  margin: 2em 0;
}
  